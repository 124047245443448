import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
"@remix-run/node";
import {
  Form,
  Link,
  redirect,
  useSearchParams,
  useSubmit } from
"@remix-run/react";
import { useEffect } from "react";

import { Button } from "@/app/components/ui/button";
import { APP_NAME } from "@/app/utils/consts";
import { getSessionId } from "@/app/utils/server/auth.server";
import loginGraphicUser1 from "@/public/img/login/user1.png";
import loginGraphicUser2 from "@/public/img/login/user2.png";

import loginStyleSheetUrl from "./index.css?url";

export const links: LinksFunction = () => {
  return [
  { rel: `preload`, href: loginStyleSheetUrl, as: `style` },
  { rel: `stylesheet`, href: loginStyleSheetUrl }];

};

export const meta: MetaFunction = () => {
  return [{ title: `Login - ${APP_NAME}` }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const sessionId = await getSessionId(request);

  if (sessionId !== undefined) {
    return redirect(`/`);
  }

  return null;
}

export default function LoginRoute() {
  const copyright = `© ${new Date().getFullYear()} Humancore`;
  const [searchParams] = useSearchParams();
  const signUpSubmit = useSubmit();

  useEffect(() => {
    const userEmail = searchParams.get(`email`);

    if (userEmail) {
      signUpSubmit(
        {},
        {
          action: `/auth?screen_hint=signup`,
          method: `POST`
        }
      );
    }
  }, [searchParams, signUpSubmit]);

  return (
    <div>
			<main>
				<section className="pane left">
					<div className="logo-container" data-testid="Login-logo">
						<img
              src={`/img/logos/logo-white.svg`}
              alt="logo"
              className="w-52" />

					</div>
					<div className="graphic mobile">
						<div className="orb" />
					</div>
					<div className="graphic lg">
						<div className="user-box user-1 before:bg-arrow">
							<img src={loginGraphicUser1} alt="Female user" />
						</div>
						<div className="user-box user-2 before:bg-arrow">
							<img src={loginGraphicUser2} alt="Male user" />
						</div>
					</div>
					<h1 className="text-content text-medium text-white">
						<em className="font-serif text-light-weight">
							Humancore <br /> empowers
						</em>
						<br />
						<span>
							individuals and organizations to perform at their very best with
							the latest in behavioral science and artificial intelligence.
						</span>
					</h1>
					<p className="copyright">{copyright}</p>
				</section>
				<section className="pane right">
					<div className="graphic lg">
						<div className="orb" />
					</div>
					<div className="content">
						<h2 className="font-serif">Get Started</h2>
						<div className="form-container">
							<Button
                asChild
                id="login-page-signup-button"
                variant="cta"
                shape="blockConstrained"
                data-testid="Login-btn-signup">

								<Link to="https://humancore.ai/">Sign Up</Link>
							</Button>
							<Form action="/auth" method="post">
								<Button
                  id="login-page-login-button"
                  variant="secondary"
                  shape="blockConstrained"
                  data-testid="Login-btn-login">

									Log In
								</Button>
							</Form>
						</div>
						<p className="font-medium">
							Get personalized, expert advice to help you and your team excel.
						</p>
						<p className="copyright">{copyright}</p>
					</div>
				</section>
			</main>
		</div>);

}